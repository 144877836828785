/*  navbarFixed*/
#navbarFixed .navbar-custom {
  background-color: transparent;
  padding: 18px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
}

#navbarFixed .navbar-custom .navbar-brand {
  margin-right: 0;
}

#navbarFixed .navbar-custom .navbar-brand .logo-light {
  display: inline-block;
}

#navbarFixed .navbar-custom .navbar-brand .logo-dark {
  display: none;
}

#navbarFixed .navbar-custom .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 14.5px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 14px;
  transition: all 0.5s;
  position: relative;
  font-weight: 500;
}

#navbarFixed
  .navbar-custom
  .navbar-collapse
  .navbar-nav
  .nav-item
  .nav-link.active {
  color: #ffffff;
}

#navbarFixed
  .navbar-custom
  .navbar-collapse
  .navbar-nav
  .nav-item
  .nav-link.active::before {
  width: 100%;
}

#navbarFixed
  .navbar-custom
  .navbar-collapse
  .navbar-nav
  .nav-item
  .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0px;
  height: 2px;
  background-color: #ffffff;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;
}

#navbarFixed .navbar-custom .navbar-collapse .nav-btn {
  padding: 7px 25px;
  border-radius: 50px;
  font-size: 13px;
  letter-spacing: 1px;
}

#navbarFixed .navbar-custom .navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #152c5c;
}

#navbarFixed .navbar-custom .navbar-toggler:focus {
  box-shadow: 0 0 0 1.25px;
}

.navbar-account {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #152c5c;
}

.navbar-account:focus {
  box-shadow: 0 0 0 1.25px;
}

/*---NAVBAR STICKY--*/
#navbarFixed .nav-sticky.navbar-custom {
  margin-top: 0px;
  padding: 18px 0px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(38, 107, 193, 0.08);
}

#navbarFixed .nav-sticky .navbar-brand .logo-dark {
  display: inline-block;
}

#navbarFixed .nav-sticky .navbar-brand .logo-light {
  display: none;
}

#navbarFixed .nav-sticky .navbar-nav .nav-item .nav-link {
  color: #252d31 !important;
}

#navbarFixed .nav-sticky .navbar-nav .nav-item .nav-link.active {
  color: #00776d !important;
}

#navbarFixed .nav-sticky .navbar-nav .nav-item .nav-link:hover {
  color: #00776d !important;
}

#navbarFixed .nav-sticky .navbar-nav .nav-item .nav-link::before {
  background-color: #00776d !important;
}

#navbarFixed .nav-sticky .nav-btn {
  background-color: #00776d;
  color: white;
}

/* .modal .modal-dialog .modal-content .modal-close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 2;
}

.modal .modal-dialog .modal-content .modal-body .modal-title {
    font-weight: 600;
}

.modal .modal-dialog .modal-content .modal-footer .footer-text {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
} */

@media (max-width: 768px) {
  #navbarFixed .navbar-custom {
    padding: 18px 0px;
    background-color: white;
  }

  #navbarFixed .navbar-custom .navbar-brand {
    margin-left: 14px;
  }

  #navbarFixed .navbar-custom .navbar-brand .logo-dark {
    display: inline-block !important;
  }

  #navbarFixed .navbar-custom .navbar-brand .logo-light {
    display: none !important;
  }

  #navbarFixed .navbar-custom .nav-item .nav-link {
    display: inline-block;
    color: #252d31 !important;
  }

  #navbarFixed .navbar-custom .nav-item .nav-link.active {
    color: #00776d;
  }

  #navbarFixed .navbar-nav .nav-item .nav-link {
    color: #252d31 !important;
  }

  #navbarFixed .navbar-nav .nav-item .nav-link.active {
    color: #00776d !important;
  }

  #navbarFixed .navbar-nav .nav-item .nav-link:hover {
    color: #00776d !important;
  }

  #navbarFixed .navbar-nav .nav-item .nav-link::before {
    background-color: #00776d !important;
  }

  #navbarFixed .nav-btn {
    background-color: #00776d !important;
    color: white !important;
    padding: 7px 25px;
    border-radius: 50px;
    font-size: 13px;
    letter-spacing: 1px;
  }
}

/* AffixBar */

#AffixBar {
  display: none;
}

@media (max-width: 991px) {
  #AffixBar {
    display: flex;
  }
}

/* banner */

#banner .section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

#banner .cta {
  position: relative;
  /* background: linear-gradient(rgba(37, 45, 49, 0.7), rgba(37, 45, 49, 0.7)), url("../public/assets/images/cta.jpg") fixed no-repeat; */
  background: linear-gradient(rgba(37, 45, 49, 0.7), rgba(37, 45, 49, 0.7)),
    url("../public/assets/images/home-2.jpeg") fixed no-repeat;

  color: #ffffff;
}

#banner .cta .cta-heading {
  font-size: 35px;
  font-weight: 600;
}

#banner .cta marquee {
  position: absolute;
  top: 0;
  background-color: rgba(37, 45, 49, 0.5);
  padding: 6px;
  font-size: 14px;
}

/* product card*/

#productCard #productCardMobile {
  display: none;
}

#productCard .pricing .pricing-box {
  transition: all 0.8s;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
}

#productCard .pricing .pricing-box:hover {
  transform: translateY(-10px);
  border: 1px solid #00776d !important;
  transition: all 0.9s;
}

#productCard .pricing .pricing-box:hover .card {
  transition: all 0.09s;
  background-color: #ffffff;
  color: #00776d;
  transition: all 0.9s;
}

#productCard .pricing .pricing-box:hover .card .card-body {
  border-bottom: none;
}

#productCard .pricing .pricing-box:hover .card .card-body h6 {
  color: #00776d;
}

#productCard .pricing .pricing-box:hover .card .card-body h2 {
  color: #00776d !important;
}

#productCard .pricing .pricing-box:hover .card .card-body h1 {
  color: #00776d;
}

#productCard .pricing .pricing-box:hover .card .card-body h1 sub {
  color: #00776d;
}

#productCard .pricing .pricing-box .pricing-lable {
  position: absolute;
  background-color: #00776d;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 90px;
  z-index: 1;
  top: -1px;
  right: 0;
  transform: rotate(-90deg) translateX(-27%);
}

#productCard .pricing .pricing-box .pricing-lable::after {
  content: "";
  border-top: 20px solid #00776d;
  border-bottom: 20px solid #00776d;
  border-left: 16px solid transparent;
  position: absolute;
  left: -15px;
  top: 0;
}

#productCard .pricing .pricing-box .card {
  border: none;
  border-radius: 0 0 9px 9px;
  background: transparent;
}

#productCard .pricing .pricing-box .card .card-body {
  padding: 25px;
  /* border-bottom: 1px solid rgba(131, 147, 147, 0.352); */
  border-bottom: none !important;
}

#productCard .pricing .pricing-box .card .card-body h6 {
  color: #00776d;
}

#productCard .pricing .pricing-box .card .card-body h1 sub {
  color: #839393;
}

#productCard .pricing .active {
  border: 1px solid #00776d !important;
  transform: translateY(-10px);
}

#productCard .pricing .active .pricing-lable {
  background-color: white;
  text-transform: uppercase;
  transition: all 0.9s;
  color: #252d31;
}

#productCard .pricing .active .pricing-lable::after {
  border-color: white;
  border-left: 16px solid transparent;
  transition: all 0.9s;
}

#productCard .pricing .active .card {
  color: #00776d;
  background-color: #ffffff;
  transition: all 0.9s;
}

#productCard .pricing .active .card .card-body {
  border-bottom: none;
}

#productCard .pricing .active .card .card-body h2 {
  color: #00776d;
}

#productCard .pricing .active .card .card-body h6 {
  color: #00776d;
}

#productCard .pricing .active .card .card-body h1 {
  color: #00776d;
}

#productCard .pricing .active .card .card-body h1 sub {
  color: #00776d;
}

@media (max-width: 768px) {
  .bg-shape {
    background: transparent;
  }

  #productCard .pricing-bg::before {
    display: none;
  }

  #productCard #productCardDesk {
    display: none;
  }

  #productCard #productCardMobile {
    display: flex;
  }
}

.portfolio-wrap {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#productCard .card-body h2 {
  color: #00776d !important;
  font-size: 1.3rem !important;
  vertical-align: middle;
  word-wrap: normal;
}

#productCard #productCardMobile .card-body h6 {
  color: #00776d;
  font-size: 0.8rem !important;
  vertical-align: middle;
  word-wrap: normal;
}

/* ========Box shadows ========== */

.common {
  box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6) !important;
}

.common2 {
  box-shadow: 0px 3px 10px 0px rgba(38, 107, 193, 0.08) !important;
}

/* product category nav */

#productCard #productCatNav .navbar {
  padding: 0;
  background: #00776d;
}

#productCard #productCatNav .navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

#productCard #productCatNav .navbar li {
  position: relative;
}

#productCard #productCatNav .navbar > ul > li {
  margin-left: 5px;
}

#productCard #productCatNav .navbar a,
#productCard #productCatNav .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px 24px 15px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

#productCard #productCatNav .navbar a i,
#productCard #productCatNav .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

#productCard #productCatNav .navbar a:hover,
#productCard #productCatNav .navbar .active,
#productCard #productCatNav .navbar .active:focus,
#productCard #productCatNav .navbar li:hover > a {
  /* background: #cc1616; */
  background: #00776d;
}

#productCard #productCatNav .navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

#productCard #productCatNav .navbar .dropdown ul li {
  min-width: 200px;
}

#productCard #productCatNav .navbar .dropdown ul a {
  padding: 10px 20px;
  color: #191919;
}

#productCard #productCatNav .navbar .dropdown ul a i {
  font-size: 12px;
}

#productCard #productCatNav .navbar .dropdown ul a:hover,
#productCard #productCatNav .navbar .dropdown ul .active:hover,
#productCard #productCatNav .navbar .dropdown ul li:hover > a {
  color: #fff;
}

#productCard #productCatNav .navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

#productCard #productCatNav .navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

#productCard #productCatNav .navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  #productCard #productCatNav .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  #productCard #productCatNav .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

@media (max-width: 991px) {
  #productCard #productCatNav .navbar {
    display: none;
  }
}

@media (min-width: 992px) {
  #productCard #productCatNavMobile {
    display: none;
  }
}

/* productCatNavMobile table */

#productCatNavMobile {
  /* background-color: #00776d; */
}

#productCatNavMobile .navTable {
  background-color: #00776d;
  margin: 0 auto;
}

#productCatNavMobile .table {
  margin-bottom: 0;
}

#productCatNavMobile .table-condensed td {
  background-color: #00776d;
  padding: 10px;
}

#productCatNavMobile .table-condensed a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}

#productCatNavMobile .table-condensed a:hover {
  font-size: 14px;
  color: #17e1ce;
  text-decoration: underline;
  text-decoration-color: #17e1ce;
  text-decoration-line: underline;
}

#productCatNavMobile .dropdown {
  display: absolute;
}

#productCatNavMobile .dropdown:hover ul {
  display: absolute;
}

#productCatNavMobile ul {
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #ccc;
  z-index: 999;
}

#productCatNavMobile ul li {
  padding: 10px;
}

#productCatNavMobile ul li a {
  padding: 10px 20px;
  color: #191919;
  font-size: 14px;
}

#productCatNavMobile ul li:hover {
  background-color: #f1f1f1;
}

#productCatNavMobile .dropdown a:hover {
  background-color: transparent;
}

#productCatNavMobile .dropdown span {
  margin-left: 5px;
}

#productCatNavMobile .bi-chevron-down {
  font-size: 14px;
}

#productCatNavMobile .subCatTable {
  margin: 0 auto;
}

#productCatNavMobile .subCatTable .table-condensed td {
  background-color: #fbfbfb;
  padding: 10px;
}

#productCatNavMobile .subCatTable .table-condensed a {
  text-decoration: none;
  padding: 10px 20px;
  color: #191919;
  font-size: 14px;
}

/* #productBanner */

.bg-overlay {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(131deg, #0f5650, transparent);
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
}

/* #productDescription  */

#productDescription .qntychHolder {
  display: flex;
  align-items: center;
  /* box-shadow: 0px 8px 20px -6px rgba(0, 119, 109, 0.6); */
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
}

#productDescription .qntychHolder:hover {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
}

#productDescription input {
  text-align: center;
  border: none !important;
  width: 60px;
  background: #fff;
}

#productDescription #qty .btn-outline-primary {
  color: #00776d;
  border-color: #00776d;
  /* border-radius: 40px; */
  background: none;
  border: none !important;
}

#productDescription #qty .btn-outline-primary:hover {
  background: none;
  color: #00776d !important;
  /* border-color: #00776d; */
  border: none !important;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  transform: none;
}

.nav-tabs .nav-link {
  color: #6c757d !important;
}

.nav-tabs .nav-link.active {
  color: #00776d !important;
}

/* ShoppingCart */

#ShoppingCart #cartTable .table thead,
th {
  background-color: #00776d !important;
}

#ShoppingCart #cartTable .table thead,
th {
  color: #f1f1f1 !important;
}

#ShoppingCart #cartTable .table td {
  color: #00776d !important;
  text-align: center;
  vertical-align: middle !important;
}

#ShoppingCart .btn-wrap {
  margin-top: 20px;
}

#ShoppingCart .btn-primary {
  background-color: #00776d;
  color: #f1f1f1 !important;
  padding: 7px 25px;
  /* border-radius: 50px; */
  font-size: 13px;
  letter-spacing: 1px;
}

#ShoppingCart .btn-outline-primary {
  padding: 7px 15px;
  /* border-radius: 50px; */
  font-size: 13px;
  letter-spacing: 1px;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
}

#ShoppingCart .btn-outline-primary:hover {
  color: #f1f1f1 !important;
  padding: 7px 15px;
  /* border-radius: 50px; */
  font-size: 13px;
  letter-spacing: 1px;
}

/* #paymentData  */

#paymentData table thead {
  background-color: #00776d;
}

/* primary button :disabled */

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #00635a !important;
  border-color: #00635a !important;
}

/*  Button cart  */

@media (max-width: 991px) {
  .cartNavbar {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .cartMobile {
    display: none;
  }
}

/* Loader css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cta {
  position: relative;
  background: linear-gradient(rgba(37, 45, 49, 0.7), rgba(37, 45, 49, 0.7)),
    url(../public/assets/images/home-2.jpeg) fixed no-repeat;
  color: #ffffff;
}
